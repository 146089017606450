
import PaginateTable from 'components/table/paginate';
import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { initOrganization } from 'utils/initData';
import { pageCount, apiStatus, permissionKeys, roleName, routePaths } from 'utils/constants';
import { isEmpty } from 'lodash';
import NoResult from 'components/commonComponent/noResult';
import { useSelector } from 'react-redux';
import ActionTable from 'components/table/actionTable';
import { orgSelector, userSelector } from 'redux/selectors';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useUser from 'hooks/useUser';
import Actions from 'components/actions';
import LabelUser from 'components/labelUser';
import { getRoles } from 'api/rolesApi';
import { deleteOrganizationUser, getOrganizationUsers } from 'api/userApi';
import SpinnerComponent from 'components/spinner';
import OrganizationTherapistModal from './organizationTherapistModal';
import ListEmpty from 'components/empty/ListEmpty';
import Svg from 'components/svg';
import ConfirmRemoveUserModal from './confirmRemoveUserModal';

const OrganizationTherapists = (props: any) => {
    const queryClient = useQueryClient();
    const { WRITE_USER } = permissionKeys;
    const [t] = useTranslation();
    const navigate = useNavigate();
    const { organizationId } = useSelector(orgSelector);
    const { userInfo } = useSelector(userSelector);
    const [searchValue, setSearchValue] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [totalEntities, setTotalEntities] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [targetOrganization, setTartgetOrganization] = useState(initOrganization);
    const [organizationTherapists, setOrganizationTherapists] = useState([]);
    const [openConfirmRemoveModal, setOpenConfirmRemoveModal] = useState(false)
    const [selectedRemoveUserId, setSelectedRemoveUserId] = useState<any>(null)
    const { fetchCurrentUser } = useUser();
    const handleAddTherapist = () => {
        setTartgetOrganization(initOrganization);
        setOpenModal(true);
        setIsEdit(false);
    };

    const { data: roles } = useQuery(['getOrganizationRoles'], () => getRoles({ page: 0, limit: 0 }), {
        staleTime: Infinity,
    });

    const { data, isLoading, isFetching } = useQuery(
        ['getOrganizationTherapists', currentPage, searchValue, organizationId],
        () =>
            getOrganizationUsers({
                page: currentPage,
                limit: pageCount,
                searchQuery: searchValue,
                roleNames: `${roleName.ORGANIZATION_ADMIN},${roleName.THERAPIST}`,
            }),
        {
            onSuccess: (data: any) => {
                setTotalEntities(data.totalEntities);
            },
            onError: () => setOrganizationTherapists([]),
            staleTime: Infinity,
        },
    );

    const handleSearch = (value: string) => {
        setSearchValue(value);
        setCurrentPage(0);
    };

    useEffect(() => {
        if (data !== undefined) {
            setOrganizationTherapists(data?.data?.entities);
            setTotalEntities(data?.data.totalEntities);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        setTotalEntities(data?.data.totalEntities);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    useEffect(() => {
        setCurrentPage(0)
    }, [organizationId])

    const handleRemoveMember = async () => {
        try {
            const data = await deleteOrganizationUser(selectedRemoveUserId);
            if (data.status === apiStatus.NO_CONTENT) {
                setOpenConfirmRemoveModal(false)
                const message: string = t('organizationTherapists.deletedSuccess');
                toast.success(message);
                queryClient.invalidateQueries('getOrganizationTherapists', { refetchActive: true }, { cancelRefetch: true });
                fetchCurrentUser();
            }
        } catch (e) {
            const message: string = t('generalErrors');
            toast.error(message);
        }
    };

    const handleViewDetail = (therapistId: string) => {
        navigate(`${routePaths.ORG_THERAPISTS}/${therapistId}`);
    };

    const AddModal: any = () => {
        return (
            openModal && (
                <OrganizationTherapistModal
                    headerTitle={isEdit ? t('organizationTherapists.editOrganizationTherapist') : t('organizationTherapists.addOrganizationTherapist')}
                    openModal={openModal}
                    targetData={targetOrganization}
                    isEdit={isEdit}
                    setOpenModal={setOpenModal}
                    queryClient={queryClient}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    roles={roles}
                />
            )
        );
    };
    if (!isLoading && isEmpty(searchValue) && totalEntities === 0) {
        return (
            <div className="w-full sm:p-8 p-4">
                <ListEmpty
                    buttonName={t('organizationTherapists.addTherapist')}
                    handleAddClick={handleAddTherapist}
                    icon={<Svg name='therapist-empty' className='h-[84px] w-auto' />}
                    title={t('titleTherapistEmpty')}
                    note={t('noteTherapistEmpty')}
                />
                {AddModal()}
            </div>
        );
    }

    return (
        <div className="w-full h-full sm:px-8 px-4">
            <div className='md:py-5'>
                <ActionTable
                    placeholderSearch={t('Search')}
                    buttonName={t('organizationTherapists.addTherapist')}
                    handleAddClick={handleAddTherapist}
                    handleSearch={handleSearch}
                />
            </div>
            {!isLoading && totalEntities === 0 && isEmpty(organizationTherapists) && <NoResult />}
            {!isEmpty(organizationTherapists) && totalEntities > 0 && (
                <>
                    <div className="overflow-x-auto mb-2 list-table">
                        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 pb-32">
                            <thead className="text-xs border-b-2 text-black dark:bg-gray-700 dark:text-gray-400 text-header">
                                <tr className="w-full">
                                    <th scope="col" className="font-medium py-3 px-4">
                                        {t('organizationTherapists.fullName')}
                                    </th>
                                    <th scope="col" className="hidden-mobile-tablet font-medium	 py-3 px-4">
                                        {t('organizationTherapists.role')}
                                    </th>
                                    <th scope="col" className="hidden-mobile font-medium py-3 px-4 ">
                                        {t('organizationTherapists.patients')}
                                    </th>
                                    <th scope="col" className="font-medium py-3 w-6" />
                                </tr>
                            </thead>
                            <tbody>
                                {organizationTherapists.map((item: any) => {
                                    return (
                                        <tr
                                            onClick={() => handleViewDetail(item?.id)}
                                            key={`${item.id}-list-organization`}
                                            className="border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-bg-hover dark:hover:bg-gray-600 cursor-pointer"
                                        >
                                            <td className="py-2 px-3 flex flex-row itemx-center text-primary-name font-medium text-gray-900 dark:text-white cursor-pointer">
                                                <LabelUser item={item} />
                                            </td>
                                            <td className="hidden-mobile-tablet text-black py-2 px-4">
                                                {item?.organizations?.find((org: any) => org?.id === organizationId)?.role?.displayName}
                                            </td>
                                            <td className="hidden-mobile text-black py-2 px-4">
                                                {item?.patients?.length}
                                            </td>
                                            <td className="py-2 ">
                                                <div onClick={e => e.stopPropagation()}>
                                                    <Actions>
                                                        <Actions.Item
                                                            action={() => handleViewDetail(item?.id)}
                                                            label={t('action.overview')} />
                                                        <Actions.Item
                                                            action={() =>{
                                                                setSelectedRemoveUserId(item?.id)
                                                                 setOpenConfirmRemoveModal(true)
                                                            }}
                                                            label={t('action.removeMember')}
                                                        />
                                                    </Actions>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                            {(isLoading || isFetching) && <SpinnerComponent />}
                        </table>
                    </div>
                    <PaginateTable setCurrentPage={setCurrentPage} currentPage={currentPage} totalEntities={totalEntities} />
                    
                </>

            )}
            {AddModal()}
            <ConfirmRemoveUserModal openConfirmRemoveModal={openConfirmRemoveModal} setOpenConfirmRemoveModal={setOpenConfirmRemoveModal} handleDelete={handleRemoveMember}/>
        </div>
    );
};

export default OrganizationTherapists;
